import React from "react"
import { BaseCSS, media } from "styled-bootstrap-grid"
import { createGlobalStyle } from "styled-components"

const SCGlobalStyle = createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }
  body{
    font-family: 'Inter';
    max-width: 100vw;
    overflow-x: hidden;
    margin:0px;
  }
  h1 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  ${media.md`
    h1 {
      font-size: 30px;
    }
  `}
`

const GlobalStyle = () => (
  <>
    <BaseCSS />
    <SCGlobalStyle />
  </>
)

export default GlobalStyle
